.settings-container__3DlP1 {
  font-family: 'Hind', sans-serif;
  color: #47475b;
  padding: 20px;
  overflow: auto;
  height: 100%;
}
.settings-title__2TUSz {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}
.settings-input__1qE80 {
  width: 100%;
  padding: 8px 6px 7px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 15px;
}
.settings-input_ip__1T3fi {
  width: 80%;
  padding: 8px 6px 7px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 15px;
}
.settings-input_container__2KvuO {
  margin: 0 20px 20px 0;
  width: 500px;
  display: inline-block;
}
.settings-checkbox__1R1DA {
  vertical-align: middle;
  margin-left: 3px;
}
.settings-error__2-AKE {
  color: #f87b6e;
}
