.conversation-items-list-view-scroll_to_bottom__3UU_j {
  position: fixed;
  top: 70px;
  right: 400px;
  width: 370px;
  background: #f86e5f;
  padding: 9px;
  color: #fff;
  text-align: center;
  z-index: 1000;
  -webkit-box-shadow: 0px 3px 13px -1px #000;
          box-shadow: 0px 3px 13px -1px #000;
  border-radius: 20px;
}
.conversation-items-list-view-scroll_to_bottom__3UU_j a {
  color: #fff;
  text-decoration: underline;
}
