.pagination-wrapper__9gzXR {
  margin: 0 auto;
  text-align: center;
}
.pagination-container__w1TSW {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination-page__1TM9h {
  display: inline;
  position: relative;
  float: left;
  color: #337ab7;
  cursor: pointer;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  min-height: 33px;
  min-width: 33px;
}
.pagination-link__2HWMJ {
  display: inline-block;
  padding: 6px 12px;
  outline: none;
}
.pagination-active__gSZqa {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
