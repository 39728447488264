.take-new-conversation-stats__Z4M6X {
  display: inline-block;
  color: #f87b6e;
  font-family: Hind;
  font-size: 16px;
  font-weight: 500;
  color: #919aa2;
}
.take-new-conversation-stats__Z4M6X strong {
  color: #6c6c7c;
}
