.inline-text-input-container__3JP2Y {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 35px);
}
.inline-text-input-container__3JP2Y .inline-text-input-input__ouwNm {
  padding-left: 5px;
  margin-left: -5px;
}
.inline-text-input-container__3JP2Y:hover .inline-text-input-icon__2JicW,
.inline-text-input-container__3JP2Y:hover .inline-text-input-copied__1uXNc {
  opacity: 1;
}
.inline-text-input-titleContainer__MH-p8 {
  display: block;
  font-weight: bold;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inline-text-input-titleContainer__MH-p8.inline-text-input-editing__2PAb3 {
  margin-left: 5px;
}
.inline-text-input-titleContainer__MH-p8 .inline-text-input-input__ouwNm {
  padding-left: 5px;
}
.inline-text-input-value_container__dbQZi {
  display: inline-block;
  padding: 0 5px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 140px;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  color: #57697b;
}
.inline-text-input-value__1C2Fx {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 25px);
  min-width: 30px;
  overflow: hidden;
  vertical-align: middle;
}
.inline-text-input-icon__2JicW {
  display: inline-block;
  width: 13px;
  height: 13px;
  line-height: 30px;
  cursor: pointer;
  opacity: 0;
  margin-left: 5px;
  vertical-align: baseline;
}
.inline-text-input-remove__3spdO {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='12' height='12' viewBox='0 0 16 16'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: %23f26d5b; fill-rule: evenodd; %7D %3C/style%3E %3C/defs%3E %3Cpath d='M16.003,2.733 L13.267,-0.003 L8.001,5.262 L2.736,-0.003 L-0.000,2.733 L5.265,7.998 L-0.000,13.263 L2.736,15.999 L8.001,10.734 L13.267,15.999 L16.003,13.263 L10.737,7.998 L16.003,2.733 Z' class='cls-1'/%3E %3C/svg%3E") center no-repeat;
  padding: 5px;
}
.inline-text-input-edit_icon__1-O6B {
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch --%3E %3Ctitle%3EC9A55290-F039-4146-93DA-718D0AFF8835%3C/title%3E %3Cdesc%3ECreated with sketchtool.%3C/desc%3E %3Cdefs%3E%3C/defs%3E %3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='Operator_edit_hover' transform='translate(-467.000000, -123.000000)' stroke='%2353A8FF'%3E %3Cg id='Page-1' transform='translate(468.000000, 124.000000)'%3E %3Cpath d='M5.02205217,14.0985043 L0.695095652,15.6769391 C0.488486957,15.7520696 0.288834783,15.5517217 0.363965217,15.3458087 L1.9424,11.0188522 L12.1740522,0.7872 C12.7597913,0.20146087 13.7093565,0.20146087 14.2950957,0.7872 L15.2537043,1.7458087 C15.8394435,2.33154783 15.8394435,3.28111304 15.2537043,3.86685217 L5.02205217,14.0985043 Z' id='Stroke-1'%3E%3C/path%3E %3Cpath d='M10.2670609,2.69405217 L13.346713,5.77370435' id='Stroke-3'%3E%3C/path%3E %3Cpath d='M11.0022261,1.95888696 L14.0818783,5.03853913' id='Stroke-5'%3E%3C/path%3E %3Cpath d='M2.00723478,10.9538783 L5.08688696,14.0335304' id='Stroke-7'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E") center no-repeat;
  padding: 5px;
  background-size: cover;
}
.inline-text-input-copy_icon__2YrGD {
  background: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1696 384q40 0 68 28t28 68v1216q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-288h-544q-40 0-68-28t-28-68v-672q0-40 20-88t48-76l408-408q28-28 76-48t88-20h416q40 0 68 28t28 68v328q68-40 128-40h416zm-544 213l-299 299h299v-299zm-640-384l-299 299h299v-299zm196 647l316-316v-416h-384v416q0 40-28 68t-68 28h-416v640h512v-256q0-40 20-88t48-76zm956 804v-1152h-384v416q0 40-28 68t-68 28h-416v640h896z'/%3E%3C/svg%3E") center no-repeat;
  padding: 5px;
  background-size: cover;
}
.inline-text-input-copied__1uXNc {
  display: inline-block;
  height: 13px;
  font-size: 13px;
  line-height: 20px;
  vertical-align: baseline;
  opacity: 0;
}
.inline-text-input-unlock_icon__3xKg1 {
  background: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100px' height='100px' viewBox='0 0 100 100' enable-background='new 0 0 100 100' xml:space='preserve'%3E %3Cpath d='M82.105,45.587h-3.714H65.29H41.236V34.945c0-5.207,4.372-9.437,9.747-9.437c5.372,0,9.744,4.23,9.746,9.437 c0,1.051,0.881,1.904,1.966,1.904H72.73c0.018,0,0.038,0,0.051,0c1.087,0,1.965-0.853,1.965-1.904c0-0.159-0.018-0.312-0.056-0.457 C74.437,22.032,63.9,11.975,50.983,11.975c-13.074,0-23.71,10.305-23.71,22.97v10.642h-5.664h-3.713 c-1.449,0-2.621,1.135-2.621,2.539v37.361c0,1.403,1.172,2.539,2.621,2.539h64.21c1.449,0,2.621-1.136,2.621-2.539V48.125 C84.726,46.722,83.554,45.587,82.105,45.587z'/%3E %3C/svg%3E") center no-repeat;
  padding: 5px;
  background-size: cover;
}
.inline-text-input-input__ouwNm {
  padding: none;
  outline: none;
  border: none;
  background-color: #fff;
  outline: 1px solid #4ea6ff;
}
.inline-text-input-inputOutlined__1angM {
  outline: 1px solid #4ea6ff;
}
.inline-text-input-gray__3gGKa {
  color: #b0bace;
}
.inline-text-input-cursor_auto__G_QXw {
  cursor: auto;
}
