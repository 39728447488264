.notification-item-container__3JpkY {
  width: 220px;
  border-radius: 3px 0 0 3px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(169,181,201,0.16);
          box-shadow: 0 2px 4px 0 rgba(169,181,201,0.16);
  background-color: #fff;
  border: solid 1px #e6eaef;
  border-right: 3px solid #919aa2;
  position: fixed;
  right: 0;
}
.notification-item-close_button__3TN4u {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  border: 0px solid;
  background-color: #fff;
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.notification-item-close_button__3TN4u span {
  background-color: #5c92ff;
  height: 2px;
  width: 15px;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.notification-item-close_button__3TN4u span:first-of-type {
  -webkit-transform: rotate(-45deg) translate(-0.9px, 0.3px);
          transform: rotate(-45deg) translate(-0.9px, 0.3px);
}
.notification-item-close_button__3TN4u span:last-of-type {
  -webkit-transform: rotate(45deg) translate(-0.9px, -0.3px);
          transform: rotate(45deg) translate(-0.9px, -0.3px);
}
.notification-item-close_button__3TN4u:hover {
  background-color: #1672ce;
}
.notification-item-close_button__3TN4u:hover span {
  background-color: #fff;
}
.notification-item-close_button__3TN4u:active,
.notification-item-close_button__3TN4u:focus {
  outline: none;
}
.notification-item-body__1zzh_ {
  margin: 15px;
}
.notification-item-name__2fzli,
.notification-item-status__9WHl- {
  padding: 0;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.notification-item-name__2fzli {
  line-height: 21px;
  color: #323d49;
  font-size: 15px;
  font-weight: 700;
}
.notification-item-status__9WHl- {
  line-height: 19px;
  color: #919aa2;
  font-size: 13px;
}
