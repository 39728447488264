.extra-info-container__3TXFX {
  padding: 23px 0;
  background-color: #47475b;
  border-bottom: 1px solid #33658a;
  position: relative;
  -webkit-transition: 0.2s ease background-color;
  transition: 0.2s ease background-color;
  cursor: pointer;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.extra-info-container__3TXFX:hover {
  background-color: #2c2f3f;
}
.extra-info-circle__IEv8i {
  background-color: #dde5ef;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
}
.extra-info-information__2l5Eq {
  float: left;
}
.extra-info-ellipsis__3E2g9 {
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.extra-info-company__2GyTz {
  color: #c7dae9;
  line-height: 1em;
  margin-bottom: 2px;
}
.extra-info-user__2zt-N {
  color: #fff;
}
.extra-info-link__1UH5h {
  display: block;
  color: #394046;
  text-decoration: none;
}
.extra-info-arrow_down__2TIv1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3.5px 0 3.5px;
  border-color: #fff transparent transparent transparent;
  position: relative;
  top: 13px;
  margin-left: 6px;
}
.extra-info-dropdown_menu__-njoU {
  position: absolute;
  bottom: 92px;
  left: 15px;
  min-height: 44px;
  min-width: 150px;
  background: #fff;
  -webkit-box-shadow: 0px 1px 6px 0px rgba(1,1,1,0.2);
          box-shadow: 0px 1px 6px 0px rgba(1,1,1,0.2);
}
.extra-info-dropdown_menu__-njoU:after {
  content: '';
  width: 10px;
  height: 13px;
  position: absolute;
  bottom: -5px;
  left: 15px;
  background: #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.extra-info-dropdown_element__IgVgx {
  position: relative;
  display: block;
  padding: 15px 25px;
  border-bottom: 1px solid #e6eaef;
  z-index: 10;
}
.extra-info-link__1UH5h {
  color: #000;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  text-align: left;
}
.extra-info-link__1UH5h.extra-info-link_center__1XWCV {
  text-align: center;
}
