.agent-edit-popup-popup__1mUIc {
  position: absolute;
  margin-left: -5px;
}
.agent-edit-popup-popup__content___p16J {
  position: absolute;
  left: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
          box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
  border: 1px solid #d3d3d3;
  padding: 11px 9px;
}
.agent-edit-popup-popup_arrow__2vZ2D {
  position: relative;
  top: 20px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: #d3d3d3;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  background-color: #fff;
}
.agent-edit-popup-popup__field__3T8US {
  font-family: Hind;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  margin-bottom: 7px;
}
.agent-edit-popup-popup__error__3NOXz {
  display: inline-block;
  color: #f87b6e;
  margin-top: 3px;
}
.agent-edit-popup-popup__input__yTgTs {
  display: block;
  width: 226px;
  padding: 7px 10px;
  color: #47475b;
  font-size: 14px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #e6eaef;
}
.agent-edit-popup-popup__input_error__3jY3u {
  border: 1px solid #f87b6e;
}
.agent-edit-popup-popup__input__yTgTs:focus {
  outline: none;
}
.agent-edit-popup-popup__buttons__3sbOc {
  text-align: right;
  margin-top: 15px;
  margin-bottom: 4px;
}
.agent-edit-popup-popup__button__3rR8i {
  margin-left: 10px;
  width: 70px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #53a8ff;
  background-color: #fff;
  font-family: Hind;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #53a8ff;
}
.agent-edit-popup-popup__button_save__2FwKU {
  color: #fff;
  background-color: #53a8ff;
}
.agent-edit-popup-popup__button_disabled__2uROz {
  color: #d4d4d4;
  border-color: #d4d4d4;
  background-color: #fff;
}
.agent-edit-popup-popup__button__3rR8i:focus {
  outline: none;
}
.agent-edit-popup-popup__button__3rR8i:active {
  -webkit-box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
          box-shadow: inset 0 2px 0 0 rgba(0,0,0,0.16), inset 0 2px 6px 0 rgba(0,0,0,0.1);
}
