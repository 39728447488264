.customer-tags-selector-container__3DoED {
  position: relative;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #e7ebf0;
  font-family: Hind;
  font-size: 14px;
  color: #a9b5c9;
  padding: 9px;
  cursor: pointer;
}
.customer-tags-selector-arrow__1f_sj {
  display: inline-block;
  float: right;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #a9b5c9 transparent transparent transparent;
  margin-top: 5px;
}
.customer-tags-selector-tags_container__Lr6HE {
  display: inline-block;
  position: absolute;
  top: 100%;
  left: 0;
}
.customer-tags-selector-tags__2S94J {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px;
  font-size: 12px;
}
.customer-tags-selector-tag__2bFLP {
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid #b7c1d3;
  border-radius: 4px;
  margin: 0 5px 5px 0;
  color: #b7c1d3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fcfcfd;
}
.customer-tags-selector-tag_hidden__3Lb_H {
  background-color: #f3f8fd;
  color: #2a86ef;
  border: 1px solid #389bfe;
}
.customer-tags-selector-tag_subscription_active__15zWv {
  background-color: #f3f8fd;
  color: #008000;
  border: 1px solid #008000;
}
.customer-tags-selector-tag_subscription_inactive__RNXJE {
  background-color: #f3f8fd;
  color: #d1d466;
  border: 1px solid #d1d466;
}
.customer-tags-selector-tag_subscription_pastdue__3DTjV {
  background-color: #f3f8fd;
  color: #f00;
  border: 1px solid #f00;
}
.customer-tags-selector-close__1khxV {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 8px;
  overflow: hidden;
  cursor: pointer;
}
.customer-tags-selector-close__1khxV:before,
.customer-tags-selector-close__1khxV:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 8px;
  top: 50%;
  left: 8px;
  margin-top: -1px;
  background-color: #7b8995;
}
.customer-tags-selector-close__1khxV:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.customer-tags-selector-close__1khxV:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
