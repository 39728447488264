.customer-payments-error__1ml1e {
  text-align: center;
  color: #f87b6e;
  font-family: Hind;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.customer-payments-no_data__FIAKO {
  font-family: Hind;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #57697b;
  text-align: center;
}
.customer-payments-container__3YVgb {
  font-family: Hind;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 20px 15px 0;
}
.customer-payments-balance_container__5i9hI {
  padding: 6px 0 13px;
  border-bottom: solid 1px #e7ebf0;
  margin-bottom: 23px;
}
.customer-payments-balance_label__1karE {
  color: #57697b;
  font-size: 17px;
  opacity: 0.7;
}
.customer-payments-balance_value__nQEdL {
  font-size: 20px;
  font-weight: bold;
  color: #57697b;
  float: right;
}
.customer-payments-links_container__2o6lY {
  margin-bottom: 20px;
}
.customer-payments-link_label__2kqa1 {
  font-size: 13px;
  color: #6c6c7c;
}
.customer-payments-link_button__1jjoT {
  font-size: 13px;
  color: #53a8ff;
  float: right;
  cursor: pointer;
}
.customer-payments-link_button_active__kw9Rh {
  color: #44bc30;
}
.customer-payments-link_input__3yfxi {
  margin-top: 6px;
  border: solid 1px #e7ebf0;
  border-radius: 3px;
  opacity: 0.8;
  font-size: 14px;
  color: #57697b;
  padding: 8px;
  width: 100%;
}
.customer-payments-link_input__3yfxi:active,
.customer-payments-link_input__3yfxi:focus {
  outline: none;
}
.customer-payments-button_container__G2wjM {
  margin-bottom: 15px;
}
